import LoconativeScroll from 'loconative-scroll'; // Changed Locomotive for Loconative

export const Locomotive = {
  setup: () => {
    const scroll = new LoconativeScroll({ // Changed Locomotive for Loconative
      el: document.querySelector('[data-inertia-container]'),
      name: 'inertia',
      smooth: true,
      class: 'is-revealed'
    });

    let currentBgElement

  function changeColor(){
    const body = document.body
    let bgElement = document.querySelector('[data-bgcolor].is-revealed')
       if(bgElement && bgElement !== currentBgElement) {
         currentBgElement = bgElement;
         body.style.background = bgElement.dataset.bgcolor
       }
      }

    scroll.on("scroll", (cords) => {
      changeColor()
    });

    changeColor()
    return scroll;
  },
};

